<template>
  <!-- 站外通知触发人员设置 -->
  <div class="container">
    <a-page-header
      :title="title"
      :subTitle="subTitle"
      @back="() => $router.go(-1)"
    />

    <div class="main-content">
      <div class="main-title">{{ subTitle }}</div>
      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span>企业员工（含外部联系方式）</span>
            <a-button type="link" @click="handleDrawerOpen(0)">
              新增员工
            </a-button>
          </div>
        </div>
      </div>

      <my-table
        :columns="typeData[this.types[0]].columns"
        :data-source="typeData[this.types[0]].dataSource"
        :pagination="typeData[this.types[0]].pagination"
        :loading="typeData[this.types[0]].loading"
        :scroll="{ x: 900 }"
        @change="staffPaginationChange"
      >
        <template slot="remark" slot-scope="record">
          <span v-if="record.remark">{{ record.remark }}</span>
          <span v-else>-</span>
        </template>
        <template slot="action" slot-scope="record">
          <a-button type="link" @click="onDel(record.id, 0)">
            删除
          </a-button>
        </template>
      </my-table>

      <div class="divider">
        <a-divider />
      </div>

      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span>平台师傅</span>
            <a-button type="link" @click="handleDrawerOpen(1)">
              新增师傅
            </a-button>
          </div>
        </div>
      </div>

      <my-table
        :columns="typeData[this.types[1]].columns"
        :data-source="typeData[this.types[1]].dataSource"
        :pagination="typeData[this.types[1]].pagination"
        :loading="typeData[this.types[1]].loading"
        :scroll="{ x: 900 }"
        @change="masterPaginationChange"
      >
        <template slot="remark" slot-scope="record">
          <span v-if="record.remark">{{ record.remark }}</span>
          <span v-else>-</span>
        </template>
        <template slot="action" slot-scope="record">
          <a-button type="link" @click="onDel(record.id, 1)">
            删除
          </a-button>
        </template>
      </my-table>
    </div>

    <drawer :title="drawerTitle" :width="375" :visible="visible">
      <a-form-model
        ref="ruleDrawerForm"
        :model="drawerForm"
        :rules="drawerRules"
      >
        <a-form-model-item label="提醒区域">
          <a-select v-model="drawerForm.overall" placeholder="请选择提醒区域">
            <a-select-option :value="0">
              指定区域提醒
            </a-select-option>
            <a-select-option :value="1">
              全区域提醒
            </a-select-option>
          </a-select>
          <a-form-model-item
            class="form-model-item-area"
            prop="areaId"
            v-if="drawerForm.overall === 0"
          >
            <a-cascader
              class="cascader"
              :options="cascaderAreaOptions"
              placeholder="请选择指定区域"
              expand-trigger="hover"
              :field-names="fieldNames"
              v-model="drawerForm.areaId"
            />
          </a-form-model-item>
        </a-form-model-item>
        <template v-if="drawerForm.userType === 'servicer'">
          <a-form-model-item label="师傅姓名" prop="name">
            <a-select
              allow-clear
              show-search
              placeholder="请输入要搜索的师傅、手机号"
              :show-arrow="false"
              :filter-option="false"
              v-model="master"
              @search="handleMasterSearch"
              @change="handleMasterChange"
            >
              <a-spin v-if="fetching" slot="notFoundContent" size="small" />
              <a-select-option v-for="d in masterSearchReturn" :key="d.id">
                {{ d.realName }}（{{ levels[d.level] }}）
              </a-select-option>
            </a-select>
            <!-- <a-input
              v-model="drawerForm.name"
              placeholder="请输入标题"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            /> -->
          </a-form-model-item>
          <a-form-model-item label="师傅手机号" ref="mobile" prop="mobile">
            <a-input
              v-model="drawerForm.mobile"
              placeholder="请输入师傅手机号"
              @blur="
                () => {
                  $refs.mobile.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
        </template>
        <template v-else>
          <a-form-model-item label="企业员工姓名" ref="name" prop="name">
            <a-input
              v-model="drawerForm.name"
              placeholder="请输入企业员工姓名"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item label="企业员工手机号" ref="mobile" prop="mobile">
            <a-input
              v-model="drawerForm.mobile"
              placeholder="请输入企业员名手机号"
              @blur="
                () => {
                  $refs.mobile.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
        </template>
        <a-form-model-item label="备注">
          <a-input
            type="textarea"
            v-model="drawerForm.remark"
            placeholder="请输入备注"
          />
        </a-form-model-item>
        <div class="drawer-btn-wrap">
          <a-button @click="handleDrawerClose">
            取消
          </a-button>
          <a-button type="primary" @click="handleDrawerSubmit">
            提交
          </a-button>
        </div>
      </a-form-model>
    </drawer>
  </div>
</template>

<script>
import drawer from '../../components/drawer/index'
import { Debounce } from '../../utils/public'
let typeId = null
let typeIndex = 0
export default {
  data() {
    return {
      title: '',
      subTitle: '',
      typeData: {
        salesman: {
          dataSource: [],
          columns: [
            {
              title: '序号',
              width: 70,
              customRender: (text, record, index) => index + 1
            },
            {
              title: '员工姓名',
              width: 100,
              dataIndex: 'name'
            },
            {
              title: '手机号（短信触达号码）',
              width: 200,
              dataIndex: 'mobile'
            },
            {
              title: '通知区域范围',
              width: 200,
              dataIndex: 'area'
            },
            {
              title: '备注',
              scopedSlots: { customRender: 'remark' }
            },
            {
              title: '操作',
              width: 100,
              fixed: 'right',
              scopedSlots: {
                customRender: 'action'
              }
            }
          ],
          loading: true,
          pagination: {
            current: 1,
            total: 0,
            defaultPageSize: 3
          }
        },
        servicer: {
          dataSource: [],
          columns: [
            {
              title: '序号',
              width: 70,
              customRender: (text, record, index) => index + 1
            },
            {
              title: '师傅姓名',
              width: 100,
              dataIndex: 'name'
            },
            {
              title: '手机号（短信触达号码）',
              width: 200,
              dataIndex: 'mobile'
            },
            {
              title: '通知区域范围',
              width: 200,
              dataIndex: 'area'
            },
            {
              title: '备注',
              scopedSlots: { customRender: 'remark' }
            },
            {
              title: '操作',
              width: 100,
              fixed: 'right',
              scopedSlots: {
                customRender: 'action'
              }
            }
          ],
          loading: true,
          pagination: {
            current: 1,
            total: 0,
            defaultPageSize: 3
          }
        }
      },
      types: ['salesman', 'servicer'],
      visible: false,
      drawerTitle: '',
      drawerForm: {
        id: null,
        userType: 'salesman',
        name: '',
        mobile: '',
        areaId: [],
        remark: '',
        overall: 0
      },
      drawerRules: {
        areaId: [
          { required: true, message: '请选择指定区域', trigger: 'change' }
        ],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }]
      },
      userTypeIds: [
        {
          id: 'salesman',
          label: '企业员工'
        },
        {
          id: 'servicer',
          label: '平台师傅'
        }
      ],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      userType: 'salesman',
      cascaderAreaOptions: [],
      masterSearchReturn: [],
      overall: 0,
      fetching: false,
      levels: {
        1: '服务师傅',
        2: '带训师傅'
      },
      master: undefined
    }
  },
  components: {
    drawer
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    typeId = query.id
    this.subTitle = query.name
    this.types.forEach((e) => {
      this.getNoticeOuterTrigger(e)
    })
  },
  methods: {
    getNoticeOuterTrigger(type) {
      // 获取站外通知触发人员列表
      // type: salesman-员工, servicer-师傅
      const td = this.typeData[type]
      const data = {
        id: typeId,
        userType: type,
        page: td.pagination.current,
        limit: 3
      }
      this.$axios.getNoticeOuterTrigger(data).then((res) => {
        const d = res.data.data
        td.dataSource = d.data
        td.pagination.total = d.count
        td.loading = false
      })
    },
    staffPaginationChange(e) {
      // 员工
      const t = this.types[0]
      const td = this.typeData[t]
      td.loading = true
      td.pagination.current = e.current
      this.getNoticeOuterTrigger(t)
    },
    masterPaginationChange(e) {
      // 师傅
      const t = this.types[1]
      const td = this.typeData[t]
      td.loading = true
      td.pagination.current = e.current
      this.getNoticeOuterTrigger(t)
    },
    onDel(id, index) {
      // 删除
      const type = this.types[index]
      this.$confirm({
        title: '确定删除吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id
          }
          this.$axios.delNoticeOuterTrigger(data).then(() => {
            this.$message.success('操作成功')
            this.getNoticeOuterTrigger(type)
          })
        }
      })
    },
    async getArea() {
      // 获取区域列表
      const area = await this.$axios.getArea()
      this.cascaderAreaOptions = area.nested
    },
    handleMasterSearch(e) {
      // 师傅搜索
      this.fetching = true
      this.masterSearch(e)
    },
    masterSearch: Debounce(function(e) {
      // 师傅搜索
      if (!e) return
      const data = {
        keyword: e
      }
      this.$axios.getMaster(data).then((res) => {
        this.masterSearchReturn = res.data.data.list
        this.fetching = false
      })
    }, 500),
    handleMasterChange(e) {
      // 师傅选择
      const item = this.masterSearchReturn.find((f) => e === f.id)
      this.drawerForm.name = item.realName
      this.drawerForm.mobile = item.mobile
    },
    handleDrawerOpen(i) {
      // 打开表单抽屉
      typeIndex = i
      this.drawerTitle = `新增${this.userTypeIds[i].label}`
      this.drawerForm.userType = this.userTypeIds[i].id
      this.visible = true
      this.getArea()
    },
    handleDrawerClose() {
      // 关闭表单抽屉
      this.visible = false
      this.master = undefined
      this.masterSearchReturn = []
      this.$refs.ruleDrawerForm.resetFields()
    },
    handleDrawerSubmit() {
      // 提交表单数据
      this.$refs.ruleDrawerForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          const d = this.drawerForm
          const data = {
            id: typeId,
            userType: this.types[typeIndex],
            name: d.name,
            mobile: d.mobile,
            areaId: d.areaId ? d.areaId[d.areaId.length - 1] : undefined,
            remark: d.remark ? d.remark : '',
            overall: d.overall // 0：区域提醒，1：全局提醒
          }
          // console.log(data)
          this.submit(data)
        }
      })
    },
    submit(e) {
      // 提交
      this.$axios.addNoticeOuterTrigger(e).then(() => {
        this.$message.success('操作成功')
        this.handleDrawerClose()
        this.getNoticeOuterTrigger(e.userType)
      })
    }
  }
}
</script>

<style scoped>
.main-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  background-color: #fff;
  padding: 32px 32px 0 32px;
}

.divider {
  background-color: #fff;
  padding: 0 32px;
}

.form-model-item-area {
  margin: 0;
}
</style>
